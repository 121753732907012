<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 2">
            <circle id="Ellipse 1" cx="10" cy="10" r="10" fill="#0296BB" />
            <g id="bracket--code-angle-programming-file-bracket">
                <path id="Vector_2 (Stroke)" fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.22781 4.21967C7.53155 3.92678 8.02401 3.92678 8.32775 4.21967L13.7722 9.46967C14.0759 9.76256 14.0759 10.2374 13.7722 10.5303L8.32775 15.7803C8.02401 16.0732 7.53155 16.0732 7.22781 15.7803C6.92406 15.4874 6.92406 15.0126 7.22781 14.7197L12.1223 10L7.22781 5.28033C6.92406 4.98744 6.92406 4.51256 7.22781 4.21967Z"
                    fill="white" />
            </g>
        </g>
    </svg>
</template>