<template>
    <SmartLinkItemComponent custom-tag="div" class="publication-item" v-if="model" :id="`_${model.system.id}`"
        :component="!model.system.workflow">
        <SmartLinkStandaloneElement custom-tag="h5" class="date" :codename="type.elements.publication_date.codename">
            {{ getFormatedDate(data.publicationDate) }}
        </SmartLinkStandaloneElement>
        <SmartLinkStandaloneElement custom-tag="h3" class="title" :codename="type.elements.publication_title.codename">
            {{ data.publicationTitle }}
        </SmartLinkStandaloneElement>
        <SmartLinkStandaloneElement custom-tag="div" class="author"
            :codename="type.elements.author_information.codename">
            {{ data.authorInformation }}
        </SmartLinkStandaloneElement>
        <SmartLinkStandaloneElement custom-tag="div" class="link"
            :codename="type.elements.link_to_publication.codename">
            <NuxtLink v-if="data.linkToPublication" :to="data.linkToPublication" target="_blank">
                View Publication
                <IconChevronRightCircle class="icon-chevron"></IconChevronRightCircle>
            </NuxtLink>
        </SmartLinkStandaloneElement>
    </SmartLinkItemComponent>
</template>

<script lang="ts" setup>
import { type PublicationItemModel, type PublicationListModel, contentTypes } from '~/models';

const type = contentTypes._publication_item
const props = defineProps<{ model: PublicationItemModel }>();
const data = computed(() => {
    return {
        authorInformation: props.model.elements.authorInformation.value,
        linkToPublication: props.model.elements.linkToPublication.value,
        publicationDate: props.model.elements.publicationDate.value ? new Date(props.model.elements.publicationDate.value) : undefined,
        publicationTitle: props.model.elements.publicationTitle.value,
    };
})

function getFormatedDate(date: Date | undefined) {
    var monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    if (!date) {
        return '';
    }

    return `${monthNames[date.getUTCMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}
</script>